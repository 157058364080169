.reliable {
  background: linear-gradient(
    to right bottom,
    #ceacb2,
    #d5b8bd,
    #e5d4d7,
    #e8e9ec,
    #dfe0e4,
    #d2d3d9
  );
}
.reliable h2 {
  color: var(--main-color);
  margin-bottom: 30px;
}
.reliable.active .descr {
  border-top: 20px solid var(--menu-back);
  background: #fff;
  padding: 20px 10px;
  border-radius: 5px;
  box-shadow: 2px -2px 5px 0 rgb(0 0 0 / 10%), -2px -2px 5px 0 rgb(0 0 0 / 10%),
    2px 2px 5px 0 rgb(0 0 0 / 10%), -2px 2px 5px 0 rgb(0 0 0 / 10%);
  transition: 0.3s all linear;
  animation-name: slideInRight;
  backface-visibility: visible !important;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-fill-mode: both;
  position: relative;
}
.reliable .container.move {
  margin-left: 35vw;
}
