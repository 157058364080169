@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.whywe {
  background: #f6c3ff;
  box-shadow: 0px 5px 10px 2px rgba(136, 91, 194, 0.4);
}
.whywe .innerSection {
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 60px;
}
.imageBlock {
  background: url("../images/19365332.png"), linear-gradient(45deg, #d5cbd3, #bd92bf);
  background-size: contain, 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1b1160;
  opacity: 1;
}
.textBlock {
  padding: 60px 0px 40px 60px;
}
.whywe .container.move {
  margin-left: 35vw;
}
.whywe .container.unmove {
  margin-left: 0 auto;
}
.whywe h2,
.whywe h4 {
  color: #853e87;
}
.whywe .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}
.whywe.active .list li {
  background: #fff;
  padding: 7px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-left: 20px solid #44f2f2;
  transition: 0.3s all linear;
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.whywe.whywe.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.whywe.whywe.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.whywe.whywe.active .list li:nth-child(3) {
  animation-delay: 2s;
}
.whywe.whywe.active .list li:nth-child(4) {
  animation-delay: 3s;
}
.whywe.whywe.active .list li:nth-child(5) {
  animation-delay: 4s;
}
.whywe.active .btn-primary {
  animation-name: flipInY;
  backface-visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  /* position: relative; */
  animation-delay: 1s;
  background: var(--contrast-color);
} 
.whywe .btn-primary:hover {
  background: #3f51b5;
  color: var(--main-color);
}
@media (max-width: 800px) { 
  .whywe .innerSection {
    display: grid;
    grid-template-columns: 100%;
  }
  .imageBlock {
    display: none;
  }
  .textBlock {
    padding: 10px;
  }
}
@media (max-width: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .whywe .innerSection {
    column-gap: 20px;
  }
  .textBlock {
    padding: 20px 10px;
  }
}
