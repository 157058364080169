@keyframes floatUp2 {
  0% {
    top: 30vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -10vh;
    opacity: 0;
  }
}

.tesimonials__external {
  position: relative;
  background: var(--second-accent-color);
  /* background-image: linear-gradient(
    to right bottom,
    #a961f1,
    #ad7ada,
    #cccdd2,
    #e9f1f2
  ); */
}
.tesimonials__external h3,
.tesimonials__external .descr {
  color: #fff;
}
.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--main-color);
  padding: 15px;
  display: block;
  border-radius: 50%;
  background-size: 24px;
  background-position-x: 8px;
}
.tesimonials__external h5 {
  color: var(--main-color);
}
.testimonial-content p {
  border: 2px solid var(--main-color);
  border-radius: 10px;
  position: relative;
  left: -20px;
  bottom: -10px;
  background: #fff;
  z-index: 20;
}

.testimonial-content p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-color);
  transform: rotate(45deg);
  left: -11px;
  bottom: 13px;
  background: #fff;
  border-right: none;
  border-top: none;
}
#testimonials .carousel.slide {
  width: 100%;
  padding: 35px;
}

#testimonials .carousel.slide p {
  font-style: italic;
}

#testimonials .user-avatar img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 20;
}
#testimonials .user-avatar h5 {
  z-index: 20;
}

.tesimonials__external .waveNew {
  position: absolute;
  left: 0;
  width: 200%;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation-name: move-forever;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.tesimonials__external .waveTop {
  top: -20px;
  z-index: 2;
  background: url("../images/tm/wt.svg");
  animation: move_wave 10s linear infinite;
}
.tesimonials__external .waveMid {
  top: -40px;
  z-index: 1;
  background: url("../images/tm/wm.svg");
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.tesimonials__external .waveBot {
  top: -60px;
  z-index: 0;
  background: url("../images/tm/wb.svg");
  animation: move_wave 15s linear infinite;
}
.tesimonials__external .container.move {
  margin-left: 35vw;
}
.tesimonials__external .container.unmove {
  margin-left: 0 auto;
}

.light2 {
  position: absolute;
  width: 0px;
  /* height: 100px; */
  opacity: 0.95;
  background-color: var(--contrast-color);
  box-shadow: #fff 0px 0px 20px 2px;
  opacity: 0;
  top: 0vh;
  /* top: 50vh;*/
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 1;
}
.x11 {
  animation: floatUp2 4s infinite linear;
  transform: scale(1);
  left: 0;
}

.x21 {
  animation: floatUp2 7s infinite linear;
  transform: scale(1.6);
  left: 15%;
}

.x31 {
  animation: floatUp2 2.5s infinite linear;
  transform: scale(0.5);
  left: -15%;
}

.x41 {
  animation: floatUp2 4.5s infinite linear;
  transform: scale(1.2);
  left: -34%;
}

.x51 {
  animation: floatUp2 8s infinite linear;
  transform: scale(2.2);
  left: -57%;
}

.x61 {
  animation: floatUp2 3s infinite linear;
  transform: scale(0.8);
  left: -81%;
}

.x71 {
  animation: floatUp2 5.3s infinite linear;
  transform: scale(3.2);
  left: 37%;
}

.x81 {
  animation: floatUp2 4.7s infinite linear;
  transform: scale(1.7);
  left: 62%;
}

.x91 {
  animation: floatUp2 4.1s infinite linear;
  transform: scale(0.9);
  left: 85%;
}
@media screen and (max-width: 1200px) {
  .slide-inner .user-avatar {
    display: flex;
    flex-flow: column;
    place-content: flex-end;
    justify-content: center;
    align-items: center;
  }
  .testimonial-content p:after {
    bottom: 50%;
  }
}
@media screen and (max-width: 992px) {
  .testimonial-content p:after {
    display: none;
  }
  #testimonials .carousel-control-prev {
    left: -10px;
  }

  #testimonials .carousel-control-next {
    right: -10px;
  }

  #testimonials .user-avatar img {
    width: 100px;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .carousel-control-prev,
  #testimonials .carousel-control-next {
    top: -200px;
  }
}
@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}
@media screen and (max-height: 450px) {
  #testimonials .user-avatar img {
    width: 150px;
  }
  .testimonial-content p {
    left: 0;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}

@media screen and (max-width: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
@media screen and (max-height: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
