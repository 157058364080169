@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap"); */
html {
  --main-color: #fff;
  --contrast-color: #853e87;
  --accent-color: #b3e0f2; /*#731425;*/
  --menu-back: #26528c;
  scroll-behavior: smooth;
  --second-accent-color: #106ad1;
}
* {
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
  font-family: "Jost", sans-serif;
  font-size: 19px;
}
.container {
  margin: 0 auto;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: #853e87;
  color: var(--contrast-color);
}
h2 {
  font-size: 1.8rem;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    visibility: hidden;

    -webkit-transform: translateX(100%);

            transform: translateX(100%);
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    visibility: hidden;

    -webkit-transform: translateX(100%);

            transform: translateX(100%);
  }
}

@-webkit-keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.App {
  position: relative;
}
.toTopButton {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  border: 2px solid #fff;
  justify-content: center;
  background: #853e87;
  color: white;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  z-index: 100;
}
.toTopButton.hidden {
  display: none;
}
.common__section .container {
  width: 1120px;
  margin: 0 auto;
  padding: 60px 0;
}

.container.move {
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.container.unmove {
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.btn-primary {
  min-width: 320px;
  max-width: 95%;
  border-radius: 10px;
  min-height: 60px;
  background: #853e87;
  background: var(--contrast-color);
  border: none;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn-primary:hover {
  background: #106ad1;
  background: var(--second-accent-color);
  color: #fff;
  color: var(--main-color);
  font-weight: bold;
  cursor: pointer;
}
@media (min-width: 1201px) and (max-width: 1365px) {
  .common__section .container {
    width: 1100px;
  }
}
@media (max-width: 1200px) {
  .container,
  .common__section .container {
    width: 100vw;
    max-width: 100vw;
    padding: 40px 10px;
  }
  .toTopButton {
    bottom: 10px;
    right: 10px;
  }
}
@media (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
  .btn-primary {
    min-height: 46px;
  }
}
@media (max-height: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
  .btn-primary {
    min-height: 46px;
  }
}

@media (max-width: 340px) {
  html,
  body {
    font-size: 12px;
  }
}

.modalOverlay {
  background-color: rgba(51, 51, 51, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 7px;
  box-shadow: 0 0 32px rgba(51, 51, 51, 0.4);
  padding: 40px;
  width: 450px;
  font-size: 26px;
  z-index: 999;
  position: relative;
}
.modalContainer .contactForm > div {
  margin-bottom: 0;
}
.modalContainer .contactForm > div:last-child {
  margin-top: 40px;
}
.modalContainer .closeForm {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: auto;
  display: block;
}
.modalContainer .closeForm:hover {
  cursor: pointer;
}
.modalContainer .contactForm {
  padding: 10px 0;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.modalContainer .contactFormWrap .alert {
  font-size: 1rem;
  text-align: center;
  place-content: center;
  width: 100%;
  margin: 0 100px;
}
.modalContainer .form-group {
  position: relative;
}

.modalContainer .imgCheckPhone,
.modalContainer .imgCheckEmail {
  width: 20px;
  position: absolute;
  bottom: 6px;
  right: 20px;
}
.modalContainer .imgCheckEmail {
  bottom: 14px;
}
.modalContainer .PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid #106ad1;
  border: 1px solid var(--second-accent-color);
}
.modalContainer .PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid #106ad1;
  border: 1px solid var(--second-accent-color);
}
.modalContainer .contactForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.modalContainer .contactForm .form-group {
  width: 100%;
}

.modalContainer .contactForm .form-group.form-user {
  padding-right: 20px;
}

.modalContainer .contactForm .cF-button {
  width: 100%;
}
.modalContainer .formTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalContainer .formTitle h2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  color: var(--main-color);
}
.modalContainer .formTitle p {
  text-align: center;
  color: #fff;
  color: var(--main-color);
  font-size: 1.1rem;
}
.modalContainer .cF-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.modalContainer .form-label {
  font-size: 1.2rem;
}
.modalContainer .contactForm .btn-primary {
  width: auto;
  max-width: 100%;
  /* background: #535f61;
  color: white; */
}
/* .contactForm .btn-primary:hover {
  background: var(--main-color);
} */
@media (max-width: 1200px) {
  .modalContainer .imgCheckEmail {
    bottom: 6px;
  }
  .modalContainer .imgCheckPhone {
    bottom: 14px;
  }
}
@media (max-width: 640px) {
  .modalContainer .formTitle h2 {
    font-size: 1.1rem;
  }
  .modalContainer .contactForm label {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .modalContainer {
    padding: 40px 10px 20px;
  }
  .modalContainer .form-group {
    margin-bottom: 0;
  }

  .modalContainer .contactForm .form-group,
  .modalContainer .contactForm .form-group.form-user {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    height: auto;
  }

  .modalContainer .contactForm > div:last-child {
    margin-top: 5px;
  }
  .modalContainer .contactForm {
    padding: 0;
  }
  .modalContainer .contactForm .btn-primary {
    padding: 5px 20px;
    font-size: 1rem;
  }
}

@media (max-height: 450px) {
  .modalContainer .closeForm {
    top: 27px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
.second {
  background: var(--contrast-color);
}
.second h2 {
  color: var(--contrast-color);
}

.second.active .rightBlock {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
  border-radius: 20px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
}
.second .container {
  padding: 40px;
  margin: 0 auto;
}
.second .contactForm {
  width: 90%;
}
.second .cF-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgCheckPhone,
.imgCheckEmail {
  width: 18px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.imgCheckPhone {
  bottom: 16px;
}
.PhoneInputInput {
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
}
.PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.form-phone,
.form-email {
  position: relative;
}
.contactForm label,
.contactForm input {
  font-size: 18px;
}
.contactForm label {
  font-weight: bold;
  color: var(--contrast-color);
}
.contactForm > div {
  margin-bottom: 15px;
}
.contactForm > div:last-child {
  margin-bottom: 0;
}
.contactForm > div:last-child {
  margin-top: 30px;
}
/* .contactForm .btn-primary {
  background: #5a30f2;
  border: none;
}
.contactForm .btn-primary:hover {
  background: var(--accent-color);
} */

@media (max-width: 1200px) {
  .second .textImage {
    width: 70%;
  }

  .contactForm {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .contactForm > div {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
  }
  .contactForm > div:last-child {
    margin-top: 0px;
  }
  .second .cF-button {
    justify-content: flex-end;
  }
  .cF-button button {
    margin-bottom: 5px;
    padding: 7px 10px;
    min-height: 0;
  }
}

@media (max-width: 800px) {
  .second .blocks.leftBlock {
    display: none;
  }
  .second .container {
    padding: 10px;
  }
  .second .innerSection {
    grid-template-columns: 100%;
  }
  .second.active .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .second .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .contactForm > div {
    width: 100%;
  }
  .second .btn-primary {
    min-width: auto;
    max-width: 100%;
    padding: 10px 20px;
  }
  .second .contactForm {
    margin: 0 auto;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .second .container {
    padding: 10px;
  }
  .second.active .rightBlock {
    margin: 10px 0;
  }
  .contactForm > div {
    width: 47%;
  }
  .second .cF-button {
    display: flex;
    align-items: center;
  }
  .second .btn-primary {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
    padding: 7px 10px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.whywe {
  background: #f6c3ff;
  box-shadow: 0px 5px 10px 2px rgba(136, 91, 194, 0.4);
}
.whywe .innerSection {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}
.imageBlock {
  background: url(/static/media/19365332.b605ba4a.png), linear-gradient(45deg, #d5cbd3, #bd92bf);
  background-size: contain, 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1b1160;
  opacity: 1;
}
.textBlock {
  padding: 60px 0px 40px 60px;
}
.whywe .container.move {
  margin-left: 35vw;
}
.whywe .container.unmove {
  margin-left: 0 auto;
}
.whywe h2,
.whywe h4 {
  color: #853e87;
}
.whywe .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}
.whywe.active .list li {
  background: #fff;
  padding: 7px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-left: 20px solid #44f2f2;
  transition: 0.3s all linear;
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.whywe.whywe.active .list li:nth-child(1) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.whywe.whywe.active .list li:nth-child(2) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.whywe.whywe.active .list li:nth-child(3) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.whywe.whywe.active .list li:nth-child(4) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.whywe.whywe.active .list li:nth-child(5) {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.whywe.active .btn-primary {
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /* position: relative; */
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  background: var(--contrast-color);
} 
.whywe .btn-primary:hover {
  background: #3f51b5;
  color: var(--main-color);
}
@media (max-width: 800px) { 
  .whywe .innerSection {
    display: grid;
    grid-template-columns: 100%;
  }
  .imageBlock {
    display: none;
  }
  .textBlock {
    padding: 10px;
  }
}
@media (max-width: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .whywe .innerSection {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  .textBlock {
    padding: 20px 10px;
  }
}

@-webkit-keyframes floatUp2 {
  0% {
    top: 30vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -10vh;
    opacity: 0;
  }
}

@keyframes floatUp2 {
  0% {
    top: 30vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -10vh;
    opacity: 0;
  }
}

.tesimonials__external {
  position: relative;
  background: var(--second-accent-color);
  /* background-image: linear-gradient(
    to right bottom,
    #a961f1,
    #ad7ada,
    #cccdd2,
    #e9f1f2
  ); */
}
.tesimonials__external h3,
.tesimonials__external .descr {
  color: #fff;
}
.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--main-color);
  padding: 15px;
  display: block;
  border-radius: 50%;
  background-size: 24px;
  background-position-x: 8px;
}
.tesimonials__external h5 {
  color: var(--main-color);
}
.testimonial-content p {
  border: 2px solid var(--main-color);
  border-radius: 10px;
  position: relative;
  left: -20px;
  bottom: -10px;
  background: #fff;
  z-index: 20;
}

.testimonial-content p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-color);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -11px;
  bottom: 13px;
  background: #fff;
  border-right: none;
  border-top: none;
}
#testimonials .carousel.slide {
  width: 100%;
  padding: 35px;
}

#testimonials .carousel.slide p {
  font-style: italic;
}

#testimonials .user-avatar img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 20;
}
#testimonials .user-avatar h5 {
  z-index: 20;
}

.tesimonials__external .waveNew {
  position: absolute;
  left: 0;
  width: 200%;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-animation-name: move-forever;
          animation-name: move-forever;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.tesimonials__external .waveTop {
  top: -20px;
  z-index: 2;
  background: url(/static/media/wt.d001a014.svg);
  -webkit-animation: move_wave 10s linear infinite;
          animation: move_wave 10s linear infinite;
}
.tesimonials__external .waveMid {
  top: -40px;
  z-index: 1;
  background: url(/static/media/wm.dd025888.svg);
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.tesimonials__external .waveBot {
  top: -60px;
  z-index: 0;
  background: url(/static/media/wb.bcbb07df.svg);
  -webkit-animation: move_wave 15s linear infinite;
          animation: move_wave 15s linear infinite;
}
.tesimonials__external .container.move {
  margin-left: 35vw;
}
.tesimonials__external .container.unmove {
  margin-left: 0 auto;
}

.light2 {
  position: absolute;
  width: 0px;
  /* height: 100px; */
  opacity: 0.95;
  background-color: var(--contrast-color);
  box-shadow: #fff 0px 0px 20px 2px;
  opacity: 0;
  top: 0vh;
  /* top: 50vh;*/
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 1;
}
.x11 {
  -webkit-animation: floatUp2 4s infinite linear;
          animation: floatUp2 4s infinite linear;
  -webkit-transform: scale(1);
          transform: scale(1);
  left: 0;
}

.x21 {
  -webkit-animation: floatUp2 7s infinite linear;
          animation: floatUp2 7s infinite linear;
  -webkit-transform: scale(1.6);
          transform: scale(1.6);
  left: 15%;
}

.x31 {
  -webkit-animation: floatUp2 2.5s infinite linear;
          animation: floatUp2 2.5s infinite linear;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  left: -15%;
}

.x41 {
  -webkit-animation: floatUp2 4.5s infinite linear;
          animation: floatUp2 4.5s infinite linear;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  left: -34%;
}

.x51 {
  -webkit-animation: floatUp2 8s infinite linear;
          animation: floatUp2 8s infinite linear;
  -webkit-transform: scale(2.2);
          transform: scale(2.2);
  left: -57%;
}

.x61 {
  -webkit-animation: floatUp2 3s infinite linear;
          animation: floatUp2 3s infinite linear;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  left: -81%;
}

.x71 {
  -webkit-animation: floatUp2 5.3s infinite linear;
          animation: floatUp2 5.3s infinite linear;
  -webkit-transform: scale(3.2);
          transform: scale(3.2);
  left: 37%;
}

.x81 {
  -webkit-animation: floatUp2 4.7s infinite linear;
          animation: floatUp2 4.7s infinite linear;
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
  left: 62%;
}

.x91 {
  -webkit-animation: floatUp2 4.1s infinite linear;
          animation: floatUp2 4.1s infinite linear;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  left: 85%;
}
@media screen and (max-width: 1200px) {
  .slide-inner .user-avatar {
    display: flex;
    flex-flow: column;
    place-content: flex-end;
    justify-content: center;
    align-items: center;
  }
  .testimonial-content p:after {
    bottom: 50%;
  }
}
@media screen and (max-width: 992px) {
  .testimonial-content p:after {
    display: none;
  }
  #testimonials .carousel-control-prev {
    left: -10px;
  }

  #testimonials .carousel-control-next {
    right: -10px;
  }

  #testimonials .user-avatar img {
    width: 100px;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .carousel-control-prev,
  #testimonials .carousel-control-next {
    top: -200px;
  }
}
@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}
@media screen and (max-height: 450px) {
  #testimonials .user-avatar img {
    width: 150px;
  }
  .testimonial-content p {
    left: 0;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}

@media screen and (max-width: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
@media screen and (max-height: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}

/* @keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
} */
.returnFunds {
  background: #fee7ff;
  box-shadow: 0px 5px 10px 2px rgba(136, 91, 194, 0.4);
}
.returnFunds.active .returnFunds__inner {
  display: flex;
  align-items: center;
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /* position: relative; */
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.returnFunds__inner h3 {
  color: #853e87;
}
.returnFundsIMG {
  display: flex;
  align-items: center;
  margin-right: 40px;
  border-radius: 50%;
  background: white;
  z-index: 3;
}
.returnFundsIMG img {
  width: 150px;
  height: auto;
}

.returnFunds__inner .textBlock {
  padding: 20px 20px 20px 100px;
  border: 4px solid #ff9800;
  border-radius: 10px;
  margin-left: -116px;
}

.returnFunds .btn-primary {
  background: var(--contrast-color);
}
.returnFunds.active .btn-primary {
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /* position: relative; */
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.returnFunds .btn-primary:hover {
  background: #895cc3;
}
@media (max-width: 800px) {
  .returnFunds .container {
    margin: 0 10px;
    padding: 0px;
    padding-bottom: 20px;
    max-width: 100%;
    width: auto;
  }
  .returnFunds__inner {
    position: relative;
    padding: 40px 0 0;
  }
  .returnFundsIMG {
    margin-right: 0;
    position: absolute;
    top: 15px;
    left: calc(50% - 25px);
  }
  .returnFundsIMG img {
    width: 50px;
  }
  .returnFunds__inner .textBlock {
    padding: 10px;
    margin-left: 0;
    max-width: 100%;
    width: auto;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .returnFunds .container {
    padding: 20px 10px;
  }
}

.reliable {
  background: linear-gradient(
    to right bottom,
    #ceacb2,
    #d5b8bd,
    #e5d4d7,
    #e8e9ec,
    #dfe0e4,
    #d2d3d9
  );
}
.reliable h2 {
  color: var(--main-color);
  margin-bottom: 30px;
}
.reliable.active .descr {
  border-top: 20px solid var(--menu-back);
  background: #fff;
  padding: 20px 10px;
  border-radius: 5px;
  box-shadow: 2px -2px 5px 0 rgb(0 0 0 / 10%), -2px -2px 5px 0 rgb(0 0 0 / 10%),
    2px 2px 5px 0 rgb(0 0 0 / 10%), -2px 2px 5px 0 rgb(0 0 0 / 10%);
  transition: 0.3s all linear;
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: relative;
}
.reliable .container.move {
  margin-left: 35vw;
}

.nav {
  display: flex;
  flex-direction: column;
  z-index: 25;
  padding: 40px 20px;
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    var(--menu-back),
    #812b3a,
    #8d404d,
    #a36670,
    /* #6732ba,
    #7d3ad2, */ #e9f1f2
  );
  height: 100vh;
  width: 30vw;
  position: fixed;
  top: 0;
  left: 0;
}
.nav.show {
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.nav.hidden {
  -webkit-animation-name: slideOutLeft;
          animation-name: slideOutLeft;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.closeForm {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.nav-link {
  width: 80%;
  color: var(--contrast-color);
  padding: 10px 20px;
}

.nav-link:hover {
  background: var(--contrast-color);
  color: var(--main-color);
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media (max-width: 1200px) {
  .nav {
    width: 100vw;
    right: 0;
    max-width: 100vw;
  }
  .nav.show {
    -webkit-animation-name: slideInRight;
            animation-name: slideInRight;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .nav.hidden {
    -webkit-animation-name: slideOutRight;
            animation-name: slideOutRight;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .nav-link {
    font-size: 1.5rem;
  }
}

@media (max-height: 450px) {
  .nav-link {
    width: 50%;
    padding: 20px;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60px;
  background: var(--menu-back);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 21;
  cursor: pointer;
}
.menu .burgerImg {
  width: 50px;
  height: 50px;
}

.burgerImg.unrotate {
  -webkit-animation-name: unrotate;
          animation-name: unrotate;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.burgerImg.rotate {
  -webkit-animation-name: rotateIn;
          animation-name: rotateIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

/*animations frames*/
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 1;
  }
}
@-webkit-keyframes unrotate {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(0, 0, 1, -100deg);
            transform: rotate(0, 0, 1, -100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}
@keyframes unrotate {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(0, 0, 1, -100deg);
            transform: rotate(0, 0, 1, -100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .menu {
    height: 50px;
    width: 100vw;
    justify-content: flex-end;
  }
  .burgerImg {
    margin-right: 20px;
  }
}

@media (max-width: 450px) {
  .menu {
    height: 40px;
  }
  .menu .burgerImg {
    width: 40px;
    height: 40px;
  }
  .burgerImg {
    margin-right: 10px;
  }
}
@media (max-height: 450px) {
  .menu {
    height: 40px;
  }
  .menu .burgerImg {
    width: 40px;
    height: 40px;
  }
  .burgerImg {
    margin-right: 10px;
  }
}
@media (max-height: 375px) {
  .menu {
    height: 30px;
  }
  .menu .burgerImg {
    width: 30px;
    height: 30px;
  }
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.howToStart {
  position: relative;
}
.howToStart .container.move {
  margin-left: 35vw;
}
.howToStart .container.unmove {
  margin-left: 0 auto;
}

.howToStart h2 {
  color: var(--contrast-color);
}
.howToStart .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 40px;
  row-gap: 40px;
  justify-content: space-around;
}

.howToStart.active .list li {
  color: var(--main-color);
  background: var(--contrast-color);
  padding: 20px 10px;
  border-radius: 5px;
  border-top: 40px solid #44f2f2;
  transition: 0.3s all linear;
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: relative;
}
.howToStart.active .btn-primary {
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: relative;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}
.list li .number {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
  top: -35px;
  left: calc(50% - 15px);
}
/* .howToStart.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.howToStart.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.howToStart.active .list li:nth-child(3) {
  animation-delay: 2s;
} */

@media (max-width: 700px) {
  .howToStart .list {
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: space-around;
  }
  .howToStart.active .list li {
    width: 48%;
  }
}
@media (max-width: 450px) {
  .howToStart.active .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart.active .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 0;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .howToStart .container {
    padding: 20px 10px;
  }
  .howToStart .list {
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.benefits .innerSection {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}
.benefits .imageBlock {
  background: url(/static/media/133y_d1gmsp1d8dsm4pbi5l9maorle9kn8u9ddlgnkp9dc9gm6qpd8hgn4qpd89m7ap8.bb485ab8.png), linear-gradient(45deg, #d5cbd3, #bd92bf);
  background-size: contain, 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1b1160;
  opacity: 1;
}

.benefits .textBlock {
  padding: 60px 60px 40px 0px;
  margin-right: 60px;
}
.benefits .container.move {
  margin-left: 35vw;
}
.benefits .container.unmove {
  margin-left: 0 auto;
}
.benefits h2,
.benefits h4 {
  color: #853e87;
}
.benefits .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}
.benefits.active .list li {
  color: var(--main-color);
  background: #cb63ce;
  padding: 7px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-right: 20px solid #44f2f2;
  transition: 0.3s all linear;
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.benefits.benefits.active .list li:nth-child(1) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.benefits.benefits.active .list li:nth-child(2) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.benefits.benefits.active .list li:nth-child(3) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.benefits.benefits.active .list li:nth-child(4) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.benefits.benefits.active .list li:nth-child(5) {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
@media (max-width: 800px) {
  .benefits .innerSection {
    grid-template-columns: 100%;
  }
  .benefits .imageBlock {
    display: none;
  }
  .benefits .textBlock {
    padding: 0;
    margin: 20px 10px;
  }
}
@media (max-width: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .benefits .innerSection {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  .benefits .textBlock {
    padding: 20px 10px;
    margin-right: 20px;
  }
}

@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
#home h1 {
    margin-bottom: 0;
}

.bannerTop {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.banner {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  justify-content: center;
  background: url(/static/media/5663804.29b88f20.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center, right center;
  z-index: 20;
}
.bannerInner {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: rgba(17, 48, 89, 0);
}

.banner .container {
  z-index: 21;
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 40px 20px;
  background: #787f84cc;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 2px #787f84cc;
  padding-left: 50px;
  /* margin: 70px auto; */
}
.banner .container .bannerText {
  display: flex;
  flex-direction: column;
}
.banner .container .bannerText > * {
  text-align: left;
}
.banner .container.move {
  margin-left: 30vw;
}
/*
.banner .container.unmove {
  margin-left: 0;
} */
h1 {
  font-size: 2.2rem;
  color: var(--main-color);
  font-weight: bold;
  margin-bottom: 40px;
}
.bannerText {
  margin-top: 60px;
}
.banner h2 {
  margin: 40px 0;
  color: var(--accent-color);
}
.banner-descr {
  line-height: 1.5;
  font-size: 1.5rem;
}
.bannerLogo {
    margin-right: 20px;
}

.bannerLogo img {
  height: 120px;
}
/* .banner .btn-primary {
  background-color: var(--accent-color);
  color: var(--main-color);
  font-weight: bold;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  padding: 10px 20px;
} */
.banner-btn {
  width: 100%;
  justify-content: start;
  display: flex;
  align-items: start;
  margin-top: 40px;
}
@media (max-width: 1200px) {
  .bannerTop {
    height: auto;
  }
  .banner {
    position: relative;
  }
  .banner .container {
    width: auto;
    max-width: 95%;
    margin: 20px auto;
  }
}
@media (max-width: 800px) {
  .bannerTop {
    height: auto;
  }
  .banner {
    position: relative;
  }
  .banner .container {
    padding: 20px;
    width: auto;
    margin: 10px auto;
  }
  .bannerLogo img {
    height: 90px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner-btn {
    margin-top: 20px;
  }
  .banner-btn .btn-primary {
    min-width: 70%;
    padding: 10px 20px;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .banner .container {
    margin: 20px;
  }
  .bannerText {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 10px;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .bannerLogo img {
    height: 90px;
  }
}
@media (max-height: 450px) {
  .bannerText {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .banner-descr {
    color: white;
  }
}
@media (max-width: 340px) {
  .bannerLogo img {
    height: 80px;
  }
}

.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 58px;
  height: 58px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  opacity: 0.8;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;  
}

.tg-icon {
    bottom: 150px;
    background: #03a9f4;
}

.whatsapp-icon:hover, .tg-icon:hover { 
    -webkit-animation-name: unset; 
            animation-name: unset;
}

.whatsapp-icon:hover, .tg-icon:hover {
  opacity: 1;
}

.whatsapp-icon svg, .tg-icon svg {
  width: 55px;
  height: 55px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
  50% {
    -webkit-transform: rotate(-15deg)scale(1.1);
            transform: rotate(-15deg)scale(1.1);
  }
  100% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
  50% {
    -webkit-transform: rotate(-15deg)scale(1.1);
            transform: rotate(-15deg)scale(1.1);
  }
  100% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
}
