.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 58px;
  height: 58px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  opacity: 0.8;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.tg-icon {
    bottom: 150px;
    background: #03a9f4;
}

.whatsapp-icon:hover, .tg-icon:hover { 
    animation-name: unset;
}

.whatsapp-icon:hover, .tg-icon:hover {
  opacity: 1;
}

.whatsapp-icon svg, .tg-icon svg {
  width: 55px;
  height: 55px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(-15deg)scale(1.1);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}