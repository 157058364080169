@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
.second {
  background: var(--contrast-color);
}
.second h2 {
  color: var(--contrast-color);
}

.second.active .rightBlock {
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-delay: 1.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
  border-radius: 20px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
}
.second .container {
  padding: 40px;
  margin: 0 auto;
}
.second .contactForm {
  width: 90%;
}
.second .cF-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgCheckPhone,
.imgCheckEmail {
  width: 18px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.imgCheckPhone {
  bottom: 16px;
}
.PhoneInputInput {
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
}
.PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.form-phone,
.form-email {
  position: relative;
}
.contactForm label,
.contactForm input {
  font-size: 18px;
}
.contactForm label {
  font-weight: bold;
  color: var(--contrast-color);
}
.contactForm > div {
  margin-bottom: 15px;
}
.contactForm > div:last-child {
  margin-bottom: 0;
}
.contactForm > div:last-child {
  margin-top: 30px;
}
/* .contactForm .btn-primary {
  background: #5a30f2;
  border: none;
}
.contactForm .btn-primary:hover {
  background: var(--accent-color);
} */

@media (max-width: 1200px) {
  .second .textImage {
    width: 70%;
  }

  .contactForm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }
  .contactForm > div {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
  }
  .contactForm > div:last-child {
    margin-top: 0px;
  }
  .second .cF-button {
    justify-content: flex-end;
  }
  .cF-button button {
    margin-bottom: 5px;
    padding: 7px 10px;
    min-height: 0;
  }
}

@media (max-width: 800px) {
  .second .blocks.leftBlock {
    display: none;
  }
  .second .container {
    padding: 10px;
  }
  .second .innerSection {
    grid-template-columns: 100%;
  }
  .second.active .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .second .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .contactForm > div {
    width: 100%;
  }
  .second .btn-primary {
    min-width: auto;
    max-width: 100%;
    padding: 10px 20px;
  }
  .second .contactForm {
    margin: 0 auto;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .second .container {
    padding: 10px;
  }
  .second.active .rightBlock {
    margin: 10px 0;
  }
  .contactForm > div {
    width: 47%;
  }
  .second .cF-button {
    display: flex;
    align-items: center;
  }
  .second .btn-primary {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
    padding: 7px 10px;
  }
}
