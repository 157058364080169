/* @keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
} */
.returnFunds {
  background: #fee7ff;
  box-shadow: 0px 5px 10px 2px rgba(136, 91, 194, 0.4);
}
.returnFunds.active .returnFunds__inner {
  display: flex;
  align-items: center;
  animation-name: flipInY;
  backface-visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  /* position: relative; */
  animation-delay: 2s;
}
.returnFunds__inner h3 {
  color: #853e87;
}
.returnFundsIMG {
  display: flex;
  align-items: center;
  margin-right: 40px;
  border-radius: 50%;
  background: white;
  z-index: 3;
}
.returnFundsIMG img {
  width: 150px;
  height: auto;
}

.returnFunds__inner .textBlock {
  padding: 20px 20px 20px 100px;
  border: 4px solid #ff9800;
  border-radius: 10px;
  margin-left: -116px;
}

.returnFunds .btn-primary {
  background: var(--contrast-color);
}
.returnFunds.active .btn-primary {
  animation-name: flipInY;
  backface-visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  /* position: relative; */
  animation-delay: 1s;
}
.returnFunds .btn-primary:hover {
  background: #895cc3;
}
@media (max-width: 800px) {
  .returnFunds .container {
    margin: 0 10px;
    padding: 0px;
    padding-bottom: 20px;
    max-width: 100%;
    width: auto;
  }
  .returnFunds__inner {
    position: relative;
    padding: 40px 0 0;
  }
  .returnFundsIMG {
    margin-right: 0;
    position: absolute;
    top: 15px;
    left: calc(50% - 25px);
  }
  .returnFundsIMG img {
    width: 50px;
  }
  .returnFunds__inner .textBlock {
    padding: 10px;
    margin-left: 0;
    max-width: 100%;
    width: auto;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .returnFunds .container {
    padding: 20px 10px;
  }
}
