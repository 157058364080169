@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.benefits .innerSection {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 60px;
}
.benefits .imageBlock {
  background: url(../images/133y_d1gmsp1d8dsm4pbi5l9maorle9kn8u9ddlgnkp9dc9gm6qpd8hgn4qpd89m7ap8.png), linear-gradient(45deg, #d5cbd3, #bd92bf);
  background-size: contain, 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1b1160;
  opacity: 1;
}

.benefits .textBlock {
  padding: 60px 60px 40px 0px;
  margin-right: 60px;
}
.benefits .container.move {
  margin-left: 35vw;
}
.benefits .container.unmove {
  margin-left: 0 auto;
}
.benefits h2,
.benefits h4 {
  color: #853e87;
}
.benefits .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}
.benefits.active .list li {
  color: var(--main-color);
  background: #cb63ce;
  padding: 7px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-right: 20px solid #44f2f2;
  transition: 0.3s all linear;
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.benefits.benefits.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.benefits.benefits.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.benefits.benefits.active .list li:nth-child(3) {
  animation-delay: 2s;
}
.benefits.benefits.active .list li:nth-child(4) {
  animation-delay: 3s;
}
.benefits.benefits.active .list li:nth-child(5) {
  animation-delay: 4s;
}
@media (max-width: 800px) {
  .benefits .innerSection {
    grid-template-columns: 100%;
  }
  .benefits .imageBlock {
    display: none;
  }
  .benefits .textBlock {
    padding: 0;
    margin: 20px 10px;
  }
}
@media (max-width: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .benefits .innerSection {
    column-gap: 20px;
  }
  .benefits .textBlock {
    padding: 20px 10px;
    margin-right: 20px;
  }
}
