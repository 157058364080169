@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
#home h1 {
    margin-bottom: 0;
}

.bannerTop {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.banner {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  justify-content: center;
  background: url("../images/banner/5663804.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center, right center;
  z-index: 20;
}
.bannerInner {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: rgba(17, 48, 89, 0);
}

.banner .container {
  z-index: 21;
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 40px 20px;
  background: #787f84cc;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 2px #787f84cc;
  padding-left: 50px;
  /* margin: 70px auto; */
}
.banner .container .bannerText {
  display: flex;
  flex-direction: column;
}
.banner .container .bannerText > * {
  text-align: left;
}
.banner .container.move {
  margin-left: 30vw;
}
/*
.banner .container.unmove {
  margin-left: 0;
} */
h1 {
  font-size: 2.2rem;
  color: var(--main-color);
  font-weight: bold;
  margin-bottom: 40px;
}
.bannerText {
  margin-top: 60px;
}
.banner h2 {
  margin: 40px 0;
  color: var(--accent-color);
}
.banner-descr {
  line-height: 1.5;
  font-size: 1.5rem;
}
.bannerLogo {
    margin-right: 20px;
}

.bannerLogo img {
  height: 120px;
}
/* .banner .btn-primary {
  background-color: var(--accent-color);
  color: var(--main-color);
  font-weight: bold;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  padding: 10px 20px;
} */
.banner-btn {
  width: 100%;
  justify-content: start;
  display: flex;
  align-items: start;
  margin-top: 40px;
}
@media (max-width: 1200px) {
  .bannerTop {
    height: auto;
  }
  .banner {
    position: relative;
  }
  .banner .container {
    width: auto;
    max-width: 95%;
    margin: 20px auto;
  }
}
@media (max-width: 800px) {
  .bannerTop {
    height: auto;
  }
  .banner {
    position: relative;
  }
  .banner .container {
    padding: 20px;
    width: auto;
    margin: 10px auto;
  }
  .bannerLogo img {
    height: 90px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner-btn {
    margin-top: 20px;
  }
  .banner-btn .btn-primary {
    min-width: 70%;
    padding: 10px 20px;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .banner .container {
    margin: 20px;
  }
  .bannerText {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 10px;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .bannerLogo img {
    height: 90px;
  }
}
@media (max-height: 450px) {
  .bannerText {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .banner-descr {
    color: white;
  }
}
@media (max-width: 340px) {
  .bannerLogo img {
    height: 80px;
  }
}
